import React from "react";
import Explore from "../components/Explore";
import TitleAndDesc from "../components/TitleAndDescription";
import { useExploreQuery } from "../hooks/Explore/useExploreQuery";
import Layout from "../components/Layout";

//  This is the Explore main page.
//  It uses the useExploreQuery hook in hooks/Explore
//  It retrives a main title, main description, and each explore item and its details to display

const MainExplore = () => {
	const data = useExploreQuery();
	console.log("Explore data", data);
	const ArrayOfExploreItems = data.allWpExplore.edges;
	console.log("array of explore items", ArrayOfExploreItems);

	return (
		<Layout>
			<div>
				<TitleAndDesc
					title={"Explore"}
					desc={
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"
					}
				/>
			</div>

			<div>
				{ArrayOfExploreItems.map((item, idx) => {
					return (
						<Explore
							key={idx}
							title={item.node.title}
							description={item.node.excerpt}
							button1={item.node.exploreLinks.button1}
							button2={item.node.exploreLinks.button2}
							button1label={item.node.exploreLinks.button1label}
							button2label={item.node.exploreLinks.button2label}
							image={item.node.featuredImage.node.gatsbyImage}
						/>
					);
				})}
			</div>
		</Layout>
	);
};

export default MainExplore;
